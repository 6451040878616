import(/* webpackMode: "eager", webpackExports: ["CognitoProvider"] */ "/vercel/path0/components/Providers/CognitoProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastManager"] */ "/vercel/path0/components/ToastManager/ToastManager.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo/alwaysGeekyGames-dark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo/voxies-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
